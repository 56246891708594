import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { roleHelper } from '@/main';
import { sitesModule } from '@/store/modules/site';
import { memberModule } from '@/store/modules/member';

@Component
export default class HomeComponent extends Vue {
    public isLoading: boolean = true;

    public get user() {
        return memberModule.member;
    }

    public async mounted() {
        if (!roleHelper.isRecreapiHomeOwner() && this.user && this.user.sites.length) {
            const active = this.user.sites[0];
            await sitesModule.setActiveSite(active);
            this.redirectToCurrentSite(active);
        } else {
            if (roleHelper.isRecreapiHomeOwner()) {
                this.$router.push({
                    name: 'my-home-owner-accommodations',
                });
            }
        }

        this.isLoading = false;
    }

    private redirectToCurrentSite(currentSite) {
        if (currentSite.siteKey) {
            this.$router.push({
                name: 'site',
                params: { siteId: currentSite.siteId, siteKey: currentSite.siteKey },
            });
        }
    }
}
